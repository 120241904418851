import React, {useState} from "react";
import { TextField, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import HighlightOffOutlinedIcon from '@material-ui/icons//HighlightOffOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsResetButton from "../SettingsResetButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";

const MAX_CHARACTER_LIMIT = 36;
const ReceiptLinesValidation = Yup.object().shape({
  receiptLine: Yup.string().max(MAX_CHARACTER_LIMIT, 
    `Each Line Should be a Max of ${MAX_CHARACTER_LIMIT} Characters`).optional(),
});

const ReceiptLines = ({ 
  lines, 
  label, 
  settingName, 
  onSubmit, 
  entityID,
  entityType,
  parentValueLines,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [currentLines, setCurrentLines] = useState(lines);
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    values,
    errors,
    handleChange,
    initialValues,
  } = useFormik({
    initialValues: { receiptLines: lines },
    validationSchema: ReceiptLinesValidation,
  });

  const handleReceiptChange = (e, index) => {
    handleChange(e);
    const targetValue = e.target?.value;
    let newLines = currentLines;
    newLines[index] = targetValue;
    setCurrentLines(newLines);
  };

  const deleteLine = async (index) => {
    let newLines = currentLines;
    newLines.splice(index, 1);
    setCurrentLines(newLines);
    onSubmit(newLines);
  };

  return (
    <>
      {currentLines.map((receiptLine, index) => {
        return (
          <Tooltip
            className={clsx("group-tooltip")}
            arrow
            title="Each line can contain a maximum of 36 characters."
            key={`helper-text-line-${index+1}`}
          >
            <TextField
              style={{ width: "100%" }}
              id="receiptLine"
              className={clsx("text-box", classes.receiptLine)}
              data-setting={`receipt-${label.toLowerCase()}`}
              label={`Receipt ${label} Line ${index+1}`}
              key={`receipt-${label.toLowerCase()}-line-${index+1}`}
              rows={4}
              variant="outlined"
              value={receiptLine}
              onChange={(e) => {
                handleReceiptChange(e, index);
              }}
              onBlur={async (e) => {
                let targetValue = e.target.value;
                let newLines = currentLines;
                newLines[index] = targetValue;
                await setCurrentLines(newLines);
                await onSubmit(newLines);
              }}
              error={Boolean(errors.receiptLine)}
              helperText={`${receiptLine.length}/${MAX_CHARACTER_LIMIT}`}
              InputProps={{endAdornment: (
                  <div
                    className={clsx(`${label.toLowerCase()}-delete-btn-container-line-${index+1}`, classes.deleteBtnContainer)}
                    onClick={async () => {
                      await deleteLine(index);
                    }}
                  >
                    <HighlightOffOutlinedIcon position="end" />
                  </div>
                )}}
              inputProps={{
                maxLength: MAX_CHARACTER_LIMIT
              }}
            />
          </Tooltip>
        )
      })}
      <Button
        className={clsx(`receipt-${label.toLowerCase()}-add-line-btn`, classes.addBtn)}
        startIcon={<AddIcon style={{ marginLeft: "33%" }}/>}
        variant="contained"
        color="primary"
        onClick={() => {
          setCurrentLines((prev) => [
            ...prev,
            ""
          ]);
        }}
      >
        {!small && `Add ${label} Line`}
      </Button>
      <SettingsResetButton
        entityID      = {entityID}
        entityType    = {entityType}
        settingName   = {settingName}
        currentValue  = {currentLines.join('\n')}
        originalValue = {parentValueLines.join('\n')}
        onClick       = {()=>{
          setCurrentLines(parentValueLines);
          onSubmit(parentValueLines)
        }}
      />
    </>
  );
};


ReceiptLines.defaultProps = {
  lines: [],
  label: "Header",
  onSubmit: () => {},
  settingName: "receipt.header",
  entityID: "",
  entityType: "",
  parentValueLines: [],
};

export default ReceiptLines;
