import React, { useEffect, useReducer } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { FindEntity } from '../../../../state/slices/entities';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { reducer, initialState } from './reducer';
import { useStyles as useRootStyles } from '../styles'
import { useStyles } from "./styles";
import { useSettingDispatchChange } from "../index";
import { useEnqueueSnackbar } from '../../../../hooks/useEnqueueSnackbar';
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectContextEntity} from "../../../../state/slices/CoreEntity";

const schema = Yup.object().shape({
    valetapiurl: Yup
      .string()
      .url('Value must be a valid URL'),
    valetstartticket: Yup
      .number()
      .typeError('Value must be a number')
      .test('maxLength', 'Value cannot exceed 12 digits', (value) => {
        if (value) {
          if (value.toString().length > 12) {
            return false
          }
        }

        return true
      }),
    valetendticket: Yup
      .number()
      .typeError('Value must be a number')
      .test('maxLength', 'Value cannot exceed 12 digits', (value) => {
        if (value) {
          if (value.toString().length > 12) {
            return false
          }
        }

        return true
      }),
})

const CVPSSettings = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const useCoreEntitySlice = useCoreEntityContext();

  const {entityId , entitySettings} = useSelector((state) => {
      const entity = useCoreEntitySlice ? selectContextEntity(state) :  state.entities?.Context;
      const entitySettings = entity?.settings;
      return {
          entityId: entity.entityid,
          entitySettings: entitySettings
      }
  })

  const { control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const rootClasses = useRootStyles();
  const classes = useStyles();
  const { dispatchSetting } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();

  const onFieldBlur = async (settingName, settingValue, inValid) => {
    if (!inValid) {
      const isSettingValueSaved = await dispatchSetting(entityId, settingName, settingValue)

      if (!isSettingValueSaved) {
        enqueueSnackbar("Unable to save CVPS setting value", {
          variant: "error",
          tag: "cvpsSettingSave",
        });
      }
    }
  }

  const onExpandCollapse = () => {
    dispatch({ type: 'SET_IS_ACCORDION_EXPANDED', payload: !state.isAccordionExpanded })
  }

  const onMaskPassword = () => {
    dispatch({ type: 'SET_IS_PASSWORD_MASKED', payload: !state.isPasswordMasked })
  }

  useEffect(() => {
    if (entitySettings && entitySettings.length) {
      let settings = {}
      let formValues = {}

      Object.entries(state.settings).forEach(([key, value]) => {
        const entitySetting = entitySettings.find((entitySetting) => entitySetting.name === value.name);

        if (entitySetting) {
          settings[key] = {
            ...value,
            name: entitySetting.name,
            value: entitySetting.value,
          }

          formValues[entitySetting.name] = entitySetting.value
        }
      })

      reset(formValues)
      dispatch({ type: 'SET_SETTINGS', payload: settings })
    }
  }, [])

  return (
    <Grid container className={rootClasses.panelRoot}>
      <Accordion
        className={rootClasses.fullWidthAccordion}
        expanded={state.isAccordionExpanded}
        onChange={onExpandCollapse}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          data-testid='cvps-settings-accordion'
        >
          <Typography className={rootClasses.heading}>CVPS Valet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            spacing={4}
            container
            direction='row'
            item
          >
            <Grid
              spacing={4}
              container
              direction='column'
              xs={12}
              md={6}
              item
            >
              <Grid
                item
              >
                <Controller
                  control={control}
                  name={state.settings.valetApiUrl.name}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={state.settings.valetApiUrl.label}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={() => {
                        onFieldBlur(field.name, field.value, fieldState.invalid)
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                      FormHelperTextProps={{
                        'data-testid': `${field.name}-helper-text`
                      }}
                      variant='outlined'
                      inputProps={{
                        'data-testid': `${field.name}-input`
                      }}
                      fullWidth
                    />
                  )}
                />
                
              </Grid>
              <Grid
                item
              >
                <Controller 
                  control={control}
                  name={state.settings.valetApiUsername.name}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={state.settings.valetApiUsername.label}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={() => {
                        onFieldBlur(field.name, field.value, fieldState.invalid)
                      }}
                      variant='outlined'
                      inputProps={{
                        'data-testid': `${field.name}-input`
                      }}
                      fullWidth
                    />
                  )}
                />
                
              </Grid>
              <Grid
                item
              >
                <Controller
                  control={control}
                  name={state.settings.valetApiPassword.name}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={state.settings.valetApiPassword.label}
                      value={field.value}
                      type={state.isPasswordMasked ? 'password' : 'text'}
                      onChange={field.onChange}                      
                      onBlur={() => {
                        onFieldBlur(field.name, field.value, fieldState.invalid)
                      }}
                      variant='outlined'
                      inputProps={{
                        'data-testid': `${field.name}-input`,
                        autoComplete: 'new-password'
                      }}
                      InputProps={{
                        endAdornment: (
                          <RemoveRedEye
                            className={classes.passwordMask}
                            onClick={onMaskPassword}
                            data-testid='unmask-password-icon'
                          />
                        )
                      }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              spacing={4}
              container
              direction='column'
              xs={12}
              md={6}
              item
            >
              <Grid
                spacing={4}
                container
                direction='row'
                item
              >
                <Grid
                  xs={12}
                  md={6}
                  item
                >
                  <Controller
                    control={control}
                    name={state.settings.valetStartTicket.name}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={state.settings.valetStartTicket.label}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={() => {
                          onFieldBlur(field.name, field.value, fieldState.invalid)
                        }}
                        error={fieldState.invalid}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        FormHelperTextProps={{
                          'data-testid': `${field.name}-helper-text`
                        }}
                        variant='outlined'
                        inputProps={{
                          'data-testid': `${field.name}-input`
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={12}
                  md={6}
                  item
                >
                  <Controller
                    control={control}
                    name={state.settings.valetEndTicket.name}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={state.settings.valetEndTicket.label}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={() => {
                          onFieldBlur(field.name, field.value, fieldState.invalid)
                        }}
                        error={fieldState.invalid}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        FormHelperTextProps={{
                          'data-testid': `${field.name}-helper-text`
                        }}
                        variant='outlined'
                        inputProps={{
                          'data-testid': `${field.name}-input`
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
              >
                <Controller
                  control={control}
                  name={state.settings.valetPropertyId.name}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={state.settings.valetPropertyId.label}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={() => {
                        onFieldBlur(field.name, field.value, fieldState.invalid)
                      }}
                      variant='outlined'
                      inputProps={{
                        'data-testid': `${field.name}-input`
                      }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default CVPSSettings
